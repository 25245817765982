import React from 'react';
import { useDecision } from '@optimizely/react-sdk';
import { useGetUserSubscriptionDetailsForLayoutQuery } from 'lib/graphql/Layouts/__generated__/LayoutsQueries.generated';
import { useCountryData } from 'lib/useCountryData';
import ComponentRandomizer from './ComponentRandomizer';
import { checkBannerHidden } from './Utils';
import { UpdatedFormsBanner } from './UpdatedFormsBanner';
import { taxFormBannerConfig, TaxFormBanner } from './TaxFormBanner';
import { MultipleSeatsInviteTeammateBanner } from './MultipleSeatsInviteTeammateBanner';
import { CSATSurveyBanner } from './CSATSurveyBanner';

// Prioritized greatest number to lowest [10..., 3, 2, 1]
export const domesticBannerPriorities: {
  MULTIPLE_SEATS: number;
  TAX_FORMS: number;
  UPDATED_FORMS: number;
} = {
  MULTIPLE_SEATS: 3,
  TAX_FORMS: 2,
  UPDATED_FORMS: 1,
};

export const i18nBannerPriorities: {
  CSAT_SURVEY: number;
} = {
  CSAT_SURVEY: 1,
};

export const RotatingNotificationBanner = (): JSX.Element => {
  const isUpdatedFormsBannerVisible = !checkBannerHidden('updatedFormsBanner');
  const isTaxFormsBannerVisible =
    taxFormBannerConfig.SHOW_BANNER &&
    !checkBannerHidden(taxFormBannerConfig.BANNER_ID);

  const { data } = useGetUserSubscriptionDetailsForLayoutQuery();
  const [decision] = useDecision('multiple_seats');
  const isCurrentSubscriber = data?.user?.isCurrentSubscriber;
  const hasSeatsAvailable =
    (data?.user?.organization?.organizationMemberDetails
      ?.numberOfSeatsRemaining || 0) >= 0;

  const countryIsoAlpha2 = useCountryData().isoAlpha2;
  const isCSATSurveryBannerVisible = !checkBannerHidden('csatSurveyBanner');

  const showInviteTeammateBanner =
    decision.variationKey === 'multiple_seats_active' &&
    !!isCurrentSubscriber &&
    hasSeatsAvailable &&
    !checkBannerHidden('multipleSeatsBanner');

  const domesticBanners = [
    {
      component: <MultipleSeatsInviteTeammateBanner />,
      conditional: () => showInviteTeammateBanner,
      priority: domesticBannerPriorities.MULTIPLE_SEATS,
    },
    {
      component: <UpdatedFormsBanner />,
      conditional: () => isUpdatedFormsBannerVisible,
      priority: domesticBannerPriorities.UPDATED_FORMS,
    },
    {
      component: <TaxFormBanner />,
      conditional: () => isTaxFormsBannerVisible,
      priority: domesticBannerPriorities.TAX_FORMS,
    },
  ];

  const i18nBanners = [
    {
      component: <CSATSurveyBanner />,
      conditional: () => isCSATSurveryBannerVisible,
      priority: i18nBannerPriorities.CSAT_SURVEY,
    },
  ];

  return (
    <ComponentRandomizer
      componentsToRandomize={
        countryIsoAlpha2 === 'US' ? domesticBanners : i18nBanners
      }
    />
  );
};
